const setContent = () => {
  const aData = eval(`i18next.translator.resourceStore.data.${i18next.language}`);
  for (const key in aData.translation) {
    $(`#${key}`).text(i18next.t(`${key}`));
  }
};

const setLanguage = (lng) => {
  i18next.init({ lng });
  setContent();
};

i18next.init({
  lng: 'en',
  resources: {
    en: {
      translation: {
        'contact': 'Contact',
        'title': 'Independent investment management',
        'subtitle': 'We are independent advisors for private investors through investment funds and segregated accounts.',
        'market-title': 'Emerging markets',
        'market-subtitle': 'We are focused on emerging and frontier markets high yield credit strategies.',
        'market-section1-title': 'Total return',
        'market-section1-text': 'Value approach to credit investments from the perspective of the economic cycle.',
        'market-section2-title': 'Cash Flow',
        'market-section2-text': 'Long-Only strategies to maximize net cash-income generation.',
        'market-section3-title': 'Diversification',
        'market-section3-text': 'The universe of emerging and frontier markets allows us to allocate credit exposure to different regions, sectors and economic cycles.',
        'contact-title': 'Contact us',
        'contact-button': 'Click here',
        'contact-input1': 'My name is',
        'contact-input2': 'Country of residence',
        'contact-input3': 'My email address is',
        'contact-input4': 'Contact reason',
        'contact-message': 'Congratulations, your message has been sent.',
        'footer-reserved': 'PrimeVo All Rights Reserved',
        'footer-link1': 'Legal Notice',
        'footer-to-top': 'To top',
        'modal-title': 'Legal Notice',
        'modal-text1': 'The purpose of this website (including any portion of this website available only to investors) is limited to providing you with general information on our services and philosophy.  We do not render or offer to render investment advice through this website.  Nothing on this website constitutes or forms a part of any offer for sale or subscription of, or any invitation to offer to buy or subscribe for, any securities, nor should it or any part of it form the basis of, or be relied upon in connection with, any contract or commitment whatsoever.',
        'modal-text2': 'Nothing on this website constitutes or forms any offer, or any invitation to engage in any of our services. We may only transact business, offer services or render investment advice in those jurisdictions where one or more of our entities are registered and/or have made the appropriate notice filing with such jurisdiction’s regulatory authority, or where we are otherwise excluded or exempted from such jurisdiction’s registration and/or notice filing requirements.'
      }
    },
    es: {
      translation: {
        'contact': 'Contacto',
        'title': 'Gestión independiente de inversiones',
        'subtitle': 'Somos asesores independientes para inversores privados a través de fondos de inversión y cuentas segregadas.',
        'market-title': 'Mercados emergentes',
        'market-subtitle': 'Estamos enfocados en estrategias de crédito de alto rendimiento en mercados emergentes y de frontera.',
        'market-section1-title': 'Retorno absoluto',
        'market-section1-text': 'Enfoque de Valor para las inversiones crediticias desde la perspectiva del ciclo económico.',
        'market-section2-title': 'Flujo de fondos',
        'market-section2-text': 'Estrategias Long-Only para maximizar la generación de ingresos de caja netos.',
        'market-section3-title': 'Diversificación',
        'market-section3-text': 'El universo de mercados emergentes y fronterizos nos permite asignar la exposición crediticia a diferentes regiones, sectores y ciclos económicos.',
        'contact-title': 'Contáctenos',
        'contact-button': 'Click aquí',
        'contact-input1': 'Mi nombre es',
        'contact-input2': 'País de residencia',
        'contact-input3': 'Mi email es',
        'contact-input4': 'Asunto',
        'contact-message': 'Felicitaciones, su mensaje fue enviado.',
        'footer-reserved': 'PrimeVo Todos los derechos reservados',
        'footer-link1': 'Aviso legal',
        'footer-to-top': 'Subir',
        'modal-title': 'Aviso legal',
        'modal-text1': 'El propósito de este sitio web (incluyendo cualquier parte de este sitio disponible sólo para inversores) se limita a proporcionarle información general sobre nuestros servicios y filosofía. No ofrecemos o brindamos asesoramiento sobre inversiones a través de este sitio. Nada en este sitio constituye o forma parte de cualquier oferta de venta o suscripción, o cualquier invitación a ofrecer o comprar o suscribir cualquier valor, ni debe ser parte o base de la misma, o ser invocada en conexión con cualquier contrato o compromiso.',
        'modal-text2': 'Nada en este sitio web constituye o forma alguna oferta, o cualquier invitación para participar en cualquiera de nuestros servicios. Sólo podemos realizar transacciones comerciales, ofrecer servicios o prestar asesoramiento sobre inversiones en aquellas jurisdicciones en las que una o más de nuestras entidades están registradas y / o han presentado la notificación apropiada con la autoridad reguladora de dicha jurisdicción, o donde estamos excluídos o exentos de tales requisitos de registro y / o notificación.'
      }
    }
  }
}, () => {
});


$('#translate-es').click(function() {
  setLanguage('es');
  $('#translate-es').addClass('active');
  $('#translate-en').removeClass('active');
});

$('#translate-en').click(function() {
  setLanguage('en');
  $('#translate-en').addClass('active');
  $('#translate-es').removeClass('active');
});
